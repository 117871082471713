import Vue from 'vue'
import request from './axios'
// 受试者列表
  //列表
export function getVisit(params) { //
  return request({
    url: '/api/sectionStructure/getSectionList',
    method: 'GET',
    params,
  })
}
  //添加
export function addVisit(data) { //
  return request({
    url: '/api/sectionStructure/saveBean',
    method: 'POST',
    data,
  })
}
  //编辑
export function editVisit(data) { //
  return request({
    url: '/api/sectionStructure/updateBean',
    // url: '/api/tFollowupPlan/updateBean',
    method: 'POST',
    data,
  })
}
  //删除
export function deleteVisit(params) { //
  return request({
    url: '/api/sectionStructure/deleteBeanByCode',
    method: 'POST',
    params,
  })
}
//表单列表
  //添加
export function addForm(data) { //
  return request({
    url: '/api/sectionStructure/saveBean',
    method: 'POST',
    data,
  })
}
  //编辑
export function editForm(data) { //
  return request({
    url: '/api/sectionStructure/updateBean',
    method: 'POST',
    data,
  })
}
  //删除
export function deleteForm(params) { //
  return request({
    url: '/api/sectionStructure/deleteBeanByCode',
    method: 'POST',
    params,
  })
}
  //移动到
export function moveForm(data) { //
  return request({
    url: '/api/sectionStructure/moveSection',
    method: 'POST',
    data,
  })
}
  //复制到
export function copyForm(data) { //
  return request({
    url: '/api/sectionStructure/copySection',
    method: 'POST',
    data,
  })
}
  //排序
export function sortForm(data) { //
  return request({
    url: '/api/tModelMappingSectionDd/lookSectionDisplayOrder',
    method: 'POST',
    data,
  })
}
//获取计划访视的关联访视和患者组信息
export  function  getPlan(data) {
    return request({
        url: '/api/sectionStructure/getPlan',
        method: 'POST',
        data,
    })
}
//添加计划访视
export  function  savePlan(data) {
    return request({
        url: '/api/sectionStructureConfig/saveBean',
        method: 'POST',
        data,
    })
}
//获取访视计划信息
export function getVisitPlanInfo(params){
    return request({
        url: '/api/sectionStructureConfig/showPlanInterviewPage',
        method: 'GET',
        params,
    })
}

// 保存实际访视时间
export function addPatientActualTime(data){
    return request({
        url: '/api/sectionStructureConfig/addPatientActualTime',
        method: 'POST',
        data,
    })
}
  //获取最后发布的版本的versionCode
export function getLastVersionCode(params) { //
  return request({
    url: '/api/tResearchVersion/getLastVersionCode',
    method: 'GET',
    params,
  })
}
  // 发布历史列表
export function getResearchPublishVersionHis(params) { //
  return request({
    url: '/api/tResearchVersion/getResearchPublishVersionHis',
    method: 'GET',
    params,
  })
}

  //发布 / 发布历史编辑
export function publishVersion(data) { //
  return request({
    url: '/api/tResearchVersion/publish',
    method: 'POST',
    data
  })
}

//获取消息推送
export function getPush(code){
  return request({
    url:'/api/sectionStructure/selectMsgDtail',
    method:'POST',
    params:{
      code
    }
  })
}
//添加消息推送
export function addPush(data){
  return request({
    url:'/api/sectionStructure/updateMsg',
    method:'POST',
    data
  })
}

