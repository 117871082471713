import Vue from 'vue'
import globalConst from './globalConst'
import {hasUserRole, hasUserType} from './index'
// 用户角色的指令
Vue.directive('authorizeRole', {
    inserted: function (el, binding) {
        let val = binding.value

        if (val && val.menuContainer.environment !== "0") {
            if (hasUserRole(val.menuContainer.codeContainer, val.itemCode) === -1) {
                if (!globalConst.devEnv)
                    el.parentNode.removeChild(el) // 删除元素
                // el.style.display ='none'
            }
        }
    }
})
// 用户类型的指令
Vue.directive('authorizeType', {
    inserted: function (el, binding) {
        let val = binding.arg
        if (val) {
            if (hasUserType(val) === -1) {
                if (!globalConst.devEnv)
                    el.parentNode.removeChild(el) // 删除元素
            }
        }
    }
})