import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../views/Login.vue'
import globalConst from '../utils/globalConst'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Research',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "research" */ '../views/Research.vue'),
        meta: {
            keepAlive: true //需要被缓存的组件
        },
    },

    {
        name: 'SystemLogDetail',
        path: '/SystemLogDetail',
        component: () => import( '../views/log/SystemLogDetail.vue'),
        meta: {
            keepAlive: true, //需要被缓存的组件
        },
    },

    {
        path: '/SystemLog',
        name: 'SystemLog',
        component: () => import( '../views/log/SystemLog.vue'),
        meta: {
            keepAlive: true, //需要被缓存的组件
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
    },
    {
        path:'/recovery',
        name:'Recovery',
        component: () => import('../views/RecoveryLIst.vue')
    },
    {
        path: '/setting',
        name: 'Setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/Setting.vue'),
        meta: {
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: '/visit',
        name: 'Visit',
        component: () => import(/* webpackChunkName: "visit" */ '../views/Visit.vue'),
        meta: {
            keepAlive: true //需要被缓存的组件
        },
        children: [
            {
                name: 'visitSelf',
                path: '/',
                component: () => import('../views/VisitSelf.vue')
            },
            {
                name: 'visitHistory',
                path: '/history',
                component: () => import('../views/VisitHistory.vue'),
                meta: {
                    history: true,
                    keepAlive: false
                }
            },
        ]
    },
    // {
    //     path: '/formEdit',
    //     name: 'FormEdit',
    //     component: () => import(/* webpackChunkName: "formEdit" */ '../views/FormEdit.vue'),
    //     meta: {
    //         keepAlive: true //需要被缓存的组件
    //     },
    // },
    {
        path: '/formEdit',
        component: () => import(/* webpackChunkName: "formEdit" */ '../views/FormEdit.vue'),
        children: [
            {
                path: 'desigin',
                name: 'formDesigin',
                component: () => import(/* webpackChunkName: "formEdit" */ '../views/FormEditDesigin.vue'),
                meta: {
                    isPreview: false
                }
            },
            {
                path: 'preview',
                name: 'formPreview',
                component: () => import('../views/FormEntryPreview.vue'),
                meta: {
                    isPreview: true
                }
            }
        ],
        meta: {
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: '/testee',
        name: 'Testee',
        component: () => import(/* webpackChunkName: "testee" */ '../views/Testee.vue'),
        meta: {
            keepAlive: true //需要被缓存的组件
        },
        children: [
            {
                path: 'view',
                name: 'TesteeView',
                component: () => import(/* webpackChunkName: "testeeView" */ '../views/TesteeView.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: 'recruitment',
                name: 'Recruitment',
                component: () => import(/* webpackChunkName: "testeeView" */ '../views/Recruitment.vue'),
                meta: {
                    index: 0
                }
            },
            {
                path: 'detail',
                name: 'TesteeDetail',
                component: () => import(/* webpackChunkName: "testeeDetail" */ '../views/TesteeDetail.vue'),
                meta: {
                    index: 2
                }
            },
            {
                path: 'query',
                name: 'TesteeQuery',
                component: () => import(/* webpackChunkName: "testeeQuery" */ '../views/TesteeQuery.vue'),
                meta: {
                    index: 3
                }
            },
            {
                path: 'trace',
                name: 'TesteeTrace',
                component: () => import(/* webpackChunkName: "testeeTrace" */ '../views/TesteeTrace.vue'),
                meta: {
                    index: 4
                }
            },
            {
                path: 'excelTask',
                name: 'ExcelTask',
                component: () => import(/* webpackChunkName: "testeeTrace" */ '../views/ExcelTask.vue'),
                meta: {
                    index: 4
                }
            },
        ],
        redirect: '/testee/view'
    },
    {
        path: '/manage',
        name: 'Manage',
        component: () => import(/* webpackChunkName: "manage" */ '../views/Manage.vue'),
        meta: {
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: '/formEntry',
        name: 'FormEntry',
        component: () => import(/* webpackChunkName: "formEntry" */ '../views/FormEntry.vue'),
        meta: {
            keepAlive: true //需要被缓存的组件
        },
    },
    {
        path: '/forgetPsw',
        name: 'ForgetPsw',
        component: () => import(/* webpackChunkName: "forgetPsw" */ '../views/ForgetPassword.vue'),
        meta: {
            keepAlive: true //需要被缓存的组件
        },
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token') || ((window.location.href.split('token=')[1]&&window.location.href.split('token=')[1].split('#')[0] )&&(window.location.href.split('token=')[1]&&window.location.href.split('token=')[1].split('#')[0] ).split('&')[0])
    console.log(token);
    if (token) {
        localStorage.setItem('token', token)
        let origin = location.origin;
        if (globalConst.prodServerUrl.indexOf("127.0.0.1") >= 0)
            origin = origin.replace("localhost", "127.0.0.1");
        let paramString = location.search.slice(1);
        var vars = paramString.split("&") || [];
        var query = {};
        for (let i = 0; i < vars.length; i++) {
            let param = vars[i].split("=");
            query[param[0]] = param[1];
        }
        if (origin == globalConst.prodServerUrl && (!paramString || !query['researchCode']) && to.name === 'Research') {
            window.location.href = globalConst.testServerUrl;
        }
        else if (to.name == 'Research' && query.env == 'prod') {
            next({
                name: 'Testee',
                query: query
            });
        } 
        next()
    }else {
        if (to.path !== '/login') {
            next({path:'/login'})
        }else{
            next()
        }
    }
})


export default router
