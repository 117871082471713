import {Message} from 'element-ui';

const globalUtil = {
    errInfo(msg, type = 'error') {
        Message({
            type: type,
            message: msg,
            center: true,
            showClose: true
        })
        return false;
    },
    ntxScopeCk(result, nc) {
        if (nc.allowDecimal == 0) {
            result = Math.round(result);
        } else {
            if (globalUtil.isNum(result)) {
                result = result.toFixed(nc.decimalDigits);
                if (result == 0)  //0.00 0.000等直接显示0
                    result = 0;
            } else
                result = '';
        }
        if (nc.scopeValid == 1) {
            if (result > nc.scopeRange_up || result < nc.scopeRange_down) {
            }
        }
        return result;
    },
    isNum(n) {
        return /^(-)?\d+(\.\d+)?$/.test(n);
    },

}
export default globalUtil;