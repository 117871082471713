import Vue from 'vue'
import request from './axios'
// 受试者列表
export function getTestee(params) { //
  return request({
    url: '/api/patient/getCaseList',
    method: 'GET',
    params,
  })
}
export function addTestee(data) { //
  return request({
    url: '/api/patient/addPatient',
    method: 'POST',
    data,
  })
}
export function deleteTestee(data) { //
  return request({
    url: '/api/patient/deleteBeanById',
    method: 'POST',
    data,
  })
}
export function getTesteeSetting(params) { //
  return request({
    url: '/api/tSiteResearch/getPatientModel',
    method: 'GET',
    params,
  })
}
//研究中心列表
export function getCenter(params) { //
  return request({
    // url: '/api/tSiteCenter/getCenterList',
    url: '/api/tSiteCenter/getCenterListByUser',
    method: 'GET',
    params,
  })
}
//质疑列表
export function getAllQuestionBySite(params) { //
  return request({
    url: '/api/tDataQuestion/getAllQuestionBySite',
    method: 'GET',
    params,
  })
}
//获取可重复访视
export function getRepeatTestee(data) { //
  return request({
    url: '/api/patient/selectRepeatable',
    method: 'POST',
    data,
  })
}
//获取可重复访视
export function addRepeatTestee(data) { //
  return request({
    url: '/api/patient/inserTDataDdValue',
    method: 'POST',
    data,
  })
}

//获取当前用户的菜单权限
export function getMenuByResearch(data) { //
    return request({
        url: '/api/tSiteResearch/getMenuByResearch',
        method: 'POST',
        data,
    })
}
//获取二维码
export function getCode(code,versionCode,patientId){
  return request({
    url:'/api/weixin/getQRcodeUrl',
    method:'POST',
    params:{
      code,
      versionCode,
      patientId
    }
  })
}
//查看邀请码
export function getInvitationCode(code,versionCode,patientId){
  return request({
    url:'api/weixin/generateCode',
    method:'POST',
    params:{
      code,
      versionCode,
      patientId
    }
  })
}
//获取query value
export function getValue(researchCode){
  return request({
    url:'api/weixin/upcomingCount',
    method:'POST',
    params:{
      researchCode
    }
  })
}
