import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './api/axios'
import './registerServiceWorker'
import '@/assets/common.scss'
import '@/assets/Iconfont/iconfont.css'
import '@/assets/Iconfont/iconfont.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/consumerDirectives'
import dayjs from "dayjs"

Vue.prototype.$time = dayjs;//可以全局使用dayjs
import globalUtil from './utils/globalUtil'

Object.keys(globalUtil).forEach((key) => {
    Vue.prototype['$' + key] = globalUtil[key];
});
import '@/utils/EchartsTheme.js'

Vue.use(ElementUI)
import {localData, sessionData} from "./utils/storage"
import globalConst from './utils/globalConst'

Vue.prototype.$gconst = globalConst;
Vue.prototype.$localData = localData;
Vue.prototype.$sessionData = sessionData;
Vue.prototype.$ajax = http
Vue.prototype.$authorContainer = {
    'environment': localData('get', 'environment'),
    'roleId': localStorage.getItem('user'),
    'codeContainer': JSON.parse(localStorage.getItem('codeContainer'))
};
Vue.config.productionTip = false
Vue.prototype.$loginName = ''
Vue.prototype.$userItCode = ''
Vue.prototype.userData = {
    userPhone: '',
    userEmail: '',
    userName: '',
    roleName: '',
};
// 测试环境
// Vue.prototype.$host = globalConst.testServerUrl

//生产环境
Vue.prototype.$host = globalConst.prodServerUrl

// if (process.env.NODE_ENV == 'development')
//     Vue.prototype.$host = globalConst.testServerUrl;
// else
//     Vue.prototype.$host = globalConst.prodServerUrl;

// if (process.env.NODE_ENV == 'development')
//     Vue.prototype.$host = globalConst.testServerUrl;
// else
//     Vue.prototype.$host = globalConst.prodServerUrl;

Vue.prototype.$user = localStorage.getItem('user') || 'CRA'
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// http.get('/api/base/getUserInfo').then(res => {
//     if (res.code == 200) {
//         Vue.prototype.$loginName = res.data.loginName;
//         Vue.prototype.$userItCode = res.data.userItCode;
//         Vue.prototype.userData = {
//             userPhone: res.data.userPhone,
//             userEmail: res.data.userEmail,
//             userName: res.data.userName,
//             roleName: res.data.roleName,
//         };
//         //###################//
//         http.get('/api/base/getUserMenu').then(menuRes => {
//             console.log('获取菜单结果', menuRes);
//             if (menuRes.code == 200) {
//                 Vue.prototype.$menuUrl = menuRes.data.userMenu.length !== 0 ? menuRes.data.userMenu[0].menuUrl : '';
//                 let userMenu = new Array()
//                 if (menuRes.data) {
//                     userMenu = menuRes.data.userMenu;
//                 }
//                 localData('set', 'userMenu', userMenu);
//                 //###################//
//                 router.beforeEach((to, from, next) => {
//                     //过滤jsessionid
//                     to.path.indexOf('jsessionid') != -1 && next('/');
//                     next();
//                 });
//                 new Vue({
//                     router,
//                     store,
//                     render: h => h(App)
//                 }).$mount('#app')
//                 //########################//
//             } else {
//                 logoOut();
//             }
//         }).catch((error) => {
//             logoOut();
//         })
//         //########################//
//     } else {
//         logoOut();
//     }
// }).catch((error) => {
//     logoOut();
// })


function logoOut() {
    http.post("/api/base/logout").then(res => {
        console.log('退出', res);
        if (res.code == 200) {
            window.localStorage.clear();
            // window.location.href = cons.CAS_PATH + "logout?service=" + cons.SERVER_PATH;
            window.location.href = res.data;
        } else {
            this.$message({
                type: 'error',
                showClose: true,
                message: res.msg,
                center: true
            });
        }
    }).catch(error => {
        console.log(error);
    });
}


