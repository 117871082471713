<template>
	<div id="nav">
		<div class="title">
			<img src="../../public/img/icons/logo.png" alt="">
			<h1>临床研究数据管理平台</h1>
		</div>
		<div class="header-right fr">
			<el-dropdown @command="handleCommand" trigger="click">
				<span class="el-dropdown-link">
					<img src="../../public/img/icons/user.png" alt="">
					<span>{{profile.userName}}</span>
					<i class="el-icon-caret-bottom el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="2" key="2">我的资料</el-dropdown-item>
					<el-dropdown-item command="3" key="3">修改密码</el-dropdown-item>
					<el-dropdown-item command="4" key="4">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<el-dialog title="修改密码" :visible.sync="dialogVisible" :show-close = 'false' width="40%" custom-class="research-dialog" :close-on-click-modal = false>
			<el-form size="mini" ref="reviseForm" :model="reviseForm" :rules="reviseRules" label-width="80px">
				<el-form-item label="旧密码" prop="oldPsw">
					<el-input type="password" v-model="reviseForm.oldPsw"></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPsw">
					<el-input type="password" v-model="reviseForm.newPsw"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="newConfirmPsw">
					<el-input type="password" v-model="reviseForm.newConfirmPsw"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-if="dialogClose==0" size="mini" @click="setPasswordTimeOut(0)">取 消</el-button>
				<el-button size="mini" type="primary" @click="reviseConfirm('reviseForm')">确 定</el-button>
			</span>
		</el-dialog>
		
		<el-dialog title="个人资料" :visible.sync="dialogVisibleProfile" width="40%" custom-class="research-dialog">
			<el-form size="mini" :model="profile" label-width="80px" disabled>
				<el-form-item label="用户名">
					<el-input v-model="profile.loginName"></el-input>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="profile.userName"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="profile.userEmail"></el-input>
				</el-form-item>
				<el-form-item label="手机">
					<el-input v-model="profile.userPhone"></el-input>
				</el-form-item>
				<el-form-item label="用户类型">
					<el-input v-model="profile.roleName"></el-input>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import {Loading} from 'element-ui';
import { mapMutations, mapState } from 'vuex';
let loadingInstance = null;
export default {
	name: 'Nav',
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			}else if (value.length<8 || value.length >16){
				callback(new Error('密码长度不符！'));
			}else if (!/^[A-Za-z0-9]+$/.test(value)){
				callback(new Error('密码格式不正确，仅支持数字与字母！'));
			} else if (value !== this.reviseForm.newPsw) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
        };
		return {

			dialogVisibleProfile: false,
			reviseForm: {
				oldPsw: '',
				newPsw: '',
				newConfirmPsw: '',
			},
			// profile: {
			// 	loginName: this.$loginName,
			// 	userName: this.userData.userName,
			// 	userEmail: this.userData.userEmail,
			// 	userPhone: this.userData.userPhone,
			// 	roleName: this.userData.roleName,
			// },
			reviseRules: {
				oldPsw: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
				newPsw: [{ required: true, message: '请输入新密码', trigger: 'blur' },
				{ pattern: /^[A-Za-z0-9]{8,16}$/, message: '请输入 8~16 位数字或字母' }],
				newConfirmPsw: [{ required: true, validator: validatePass, trigger: 'blur' },],
			},
			//倒计时返回登录
			countdown: 3,
		}
	},
	computed: {
		... mapState(['passwordTimeOut', 'dialogClose', 'userData']),
		dialogVisible(){
			console.log(this.passwordTimeOut);
			return this.passwordTimeOut == 1
		},
		profile(){
			console.log(this.userData);
			return {... this.userData}
		}
	},
	methods: {
		... mapMutations(['setPasswordTimeOut', 'setDialogClose']),
		handleCommand(index) {
			if(index === "2") {
				this.dialogVisibleProfile = true;
			} else if(index === "3") {
				this.reviseForm = this.$options.data.call(this).reviseForm;
            	this.$refs.reviseForm && this.$refs.reviseForm.clearValidate();
				// this.dialogVisible = true;
				this.setPasswordTimeOut(1)
				localStorage.setItem('dialogClose',1)
				this.setDialogClose(0)
			}else if(index === "4") {
				// 退出登录，清除数据
				this.logoOut();
			}
		},
		reviseConfirm(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					loadingInstance = Loading.service({fullscreen: true, text: '修改密码提交中，请稍等...',})
					console.log(this.userData);
					const postData = {
						userItCode: this.userData.userItCode,
						passwordNew: this.reviseForm.newPsw,
						passwordOld: this.reviseForm.oldPsw
					}
					this.$ajax.post("/api/base/updatePassword",postData).then(res => {
						console.log('修改密码', res);
						if(res.code == 200) {
							// this.logoOut();
							localStorage.setItem('passwordTimeOut', 0)
							this.setPasswordTimeOut(0)
							this.$message({
								type: 'success',
								showClose: true,
								message: res.msg,
								center: true
							});
							loadingInstance.close();
							// let timer = null;
							// loadingInstance = Loading.service({fullscreen: true, text: '密码修改成功，'+this.countdown+'s后返回登录页面。',})
							// timer = setInterval(() => {
							// 	if(this.countdown-- === 0){
							// 		clearInterval(timer);
							// 		this.logoOut();
							// 	}
							// 	loadingInstance = Loading.service({fullscreen: true, text: '密码修改成功，'+this.countdown+'s后返回登录页面。',})
							// }, 1000);
						}else{
							loadingInstance.close();
							this.$message({
								type: 'error',
								showClose: true,
								message: res.msg,
								center: true
							});
						}
					}).catch(error => {
						loadingInstance.close();
						console.log(error);
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		logoOut() {
			localStorage.removeItem('token')
			this.$router.replace('/login')
			// this.$ajax.post("/api/base/logout").then(res => {
			// 	console.log('退出', res);
			// 	if(res.code == 200) {
			// 		window.localStorage.clear();
			// 		// window.location.href = cons.CAS_PATH + "logout?service=" + cons.SERVER_PATH;
			// 		window.location.href = res.data;
			// 	}else{
			// 		this.$message({
			// 			type: 'error',
			// 			showClose: true,
			// 			message: res.msg,
			// 			center: true
			// 		});
			// 	}
			// }).catch(error => {
			// 	console.log(error);
			// });
		}
	},
	beforeRouteLeave(to,from,next){
             loadingInstance && loadingInstance.close();
             next()
        }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	#nav{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 48px;
		background: #273549;
		line-height: 48px;
		.title{
			display: flex;
			text-align: left;
			padding-left: 16px;
			font-size: 24px;
			font-weight: 400;
			color: #fff;
			float: left;
			img{
				display: inline-block;
				height: 45px;
				vertical-align: middle;
				margin-right: 20px;
			}
			h1{
				display: inline-block;
				vertical-align: middle;
				font-size: 24px;
				color: #fff;
			}
		}
		.header-right {
			margin-right: 25px;
			cursor: pointer;
			i,span{
				color: #fff;
			}
			img{
				vertical-align: middle;
				margin: -5px 10px 0 0;
			}
		}
	}
</style>
