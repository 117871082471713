<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Login</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
	<Nav v-if="isLogin == 0" />
	<!-- <keep-alive >
		<router-view v-if="$route.meta.keepAlive"> </router-view>
	</keep-alive>
	<router-view v-if="	!$route.meta.keepAlive" /> -->
	<router-view></router-view>
  </div>
</template>

<script>
import { mapState ,mapMutations } from 'vuex'
import Nav from './components/Nav.vue'
export default {
    name: 'Testee',
    components: {
        Nav
	},
	computed:{
		... mapState(['isLogin'])
	},
	watch: {
		'$route.name'(n){
			console.log(n);
			if (n === 'Login') {
				localStorage.setItem('isLogin',1)
				this.setIsLogin(1)
			}else {
				localStorage.setItem('isLogin',0)
				this.setIsLogin(0)
			}
		}
	},
	methods: {
		... mapMutations(['setIsLogin']),
	}
}
</script>

<style lang="scss">
/deep/.el-loading-spinner .path{
	stroke: #2c5c89 !important;
}

/deep/.el-button{
	background: #273549;
}
	#app{
		width: 100%;
		height: 100%;
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	// 隐藏滚动条
	::-webkit-scrollbar {
		// display:none;
	}
	//列表操作栏多余边线处理
	.el-table__fixed-right::before, .el-table__fixed::before{
		height: 0 !important;
	}
</style>
