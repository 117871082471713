export const mutations = {
  setTabPosition(state,v){
    state.tabPosition =v
  },
  setIsEdit(state,v){
      state.isEdit = v;
  },
  setDataList(state,v){
    state.dataList = v;
  },
  setSendDialogFormVisible(state,v){
    state.sendDialogFormVisible = v;
  },
  setSendForm(state,v){
    state.sendForm = v;
  },
  setHistoryTable(state,v){
    state.historyTable = v;
  },
  setPageConfig(state,v){
    state.pageConfig = v;
  },
  setIsFineshed(state,v){
    state.isFineshed = v;
  },
  setVersionFineshed(state,v){
    state.versionFineshed = v;
  },
  setVersionData(state,v){
    localStorage.setItem('versionCode', JSON.stringify(v.versionCode))
    state.versionData = v;
  },
  setIsLogin(state,v){
    state.isLogin = v
  },
  setPasswordTimeOut(state,v){
    state.passwordTimeOut = v
  },
  setDialogClose(state, v){
    state.dialogClose = v
  },
  setUserData(state, v){
    state.userData = v
  },
  setTrailList(state,v){
    state.trailList.list = state.trailList.list.concat([... v.d.list])
    state.trailList.count = v.d.count
    v.cb()
  },
  clearTrailList(state,v){
    state.trailList.list = []
    state.trailList.count = 0
    v.cb()
  },
  saveValue(state,value){
    state.value = value
  }
  
}