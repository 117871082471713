import {getResearchPublishVersionHis,publishVersion,getVisit, getLastVersionCode} from "@/api/visit"
import {
  getValue
} from "@/api/testee";
import {getTrailList} from "@/api/formEntry";
import {Message, Loading} from 'element-ui'
let loadingInstance = null;
export const actions = {
  getResearchPublishVersions({commit,state},obj){
    loadingInstance = Loading.service({fullscreen: true, text: 'Loading',});
    getResearchPublishVersionHis({... obj, ... state.pageConfig}).then((res)=>{
      if (res.code == 200) {
        commit('setHistoryTable',res.data.records)
        commit('setPageConfig',{
          current:res.data.current,
          size:res.data.size,
          total:res.data.total
        })
      }
      loadingInstance.close();
    }).catch((error)=>{
      loadingInstance.close();
    })
  },
  onPublishVersion({dispatch,commit,state},obj){
    loadingInstance = Loading.service({fullscreen: true, text: 'Loading',});
    publishVersion({... obj, ... state.sendForm}).then((res)=>{
      if (res.code == 200) {
          commit('setSendDialogFormVisible',false)
          commit('setSendForm',{
              publishEnv:1,
              versionNum:'',
              versionDate:'',
              versionCode:'',
              remark:''
          })
          localStorage.setItem('versionCode',JSON.stringify(res.data.versionCode))

          const postData = {
              researchCode: res.data.researchCode,
              versionCode: res.data.versionCode
          };
          dispatch('getVisitList',postData)
        }else {
            type = 'error'
            msg = '发布失败'
        }
    }).catch((error)=>{

    })
  },
  getVisitList({commit},postData) {
    commit('setDataList',[])
    getVisit(postData)
        .then(res => {
            console.log('获取访视列表', res);
            commit('setDataList',res.data.dataList)
            Message({
              message: res.msg,
              type: 'success',
              duration: 2 * 1000
          })
          loadingInstance.close();
        })
        .catch(error => {
          loadingInstance.close();
        });
  },
  getLastVersionCode({commit},v){
    commit('setVersionFineshed',false)
    getLastVersionCode({... v.v}).then((res)=>{
      if (res.code == 200) {
        commit('setVersionData',res.data)
        localStorage.setItem('setVersionData',JSON.stringify(res.data))
        commit('setVersionFineshed',true)
        v.cb()
      }
    }).catch((error)=>{

    })
  },
  onGetTrailList({commit},v){
    if (v.obj.current == 1) {
      commit('clearTrailList', v)
    }
    getTrailList({... v.obj}).then((res)=>{
      commit('setTrailList', {d:res.data, cb:v.cb})
    }).catch((err)=>{
      console.log(err);
    })
  },
  getQueryValue({commit},data){
      getValue(data).then( res =>{
            if(res.data > 0) commit('saveValue',res.data)
        }).catch( error => {
      })
  }
}