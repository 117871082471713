const globalConst = {

    //上线打包改成false
    devEnv: false,
    testServerUrl: 'http://www.dhcmetis.com',
    prodServerUrl: 'http://production.dhcmetis.com',
    // testServerUrl: 'http://172.17.220.61:9003',
    // prodServerUrl: 'http://172.17.220.60:9005',
    // testServerUrl: 'http://10.131.101.79:8081',
    // prodServerUrl: 'http://10.254.248.13:8081',
    RESEARCH_VERSION_CODE: "versionCode",
    RESEARCH_CODE: "researchCode",
    ENV: "operatingEnv",
    calculatorSymbol: {
        power2: '²',
        power3: '³',
        genhao: '√',
        deleteBtn: 'DEL',
        clearBtn: 'C',
        // addBtn:'+',
        // addBtn:'-',
        // addBtn:'*',ß
        // addBtn:'/',
        // addBtn:'(',
        // addBtn:')',
    }
}

export const calculatorConst = {
    dateUnit: {
        YEAR: 1,
        MONTH: 2,
        DAT: 3,
        HOUR: 4,
        MINUTE: 5,
    }
}

export const tableHeader = {

    /*
        hideMore: true 内容太长以省略号展示
        sort: 'custom' 开启自定义排序
     */
    systemLogOper: [
        // {prop: 'env', label: '操作环境',},
        {prop: 'logType', label: '日志类型',},
        {prop: 'researchVersionNum', label: '操作版本',},
        {prop: 'model', label: '操作模块',},
        {prop: 'operateType', label: '操作类型',},
        {prop: 'operateDesc', label: '操作内容', hideMore: true},
        {prop: 'userName', label: '操作人'},
        {prop: 'operateDate', label: '操作日期'},
        {prop: 'ip', label: 'IP地址'},
    ],
    systemLogException: [
        // {prop: 'env', label: '操作环境',},
        {prop: 'logType', label: '日志类型',},
        {prop: 'researchCode', label: '研究code',},
        {prop: 'actionClass', label: '后端调用类', hideMore: true},
        {prop: 'actionMethod', label: '后端调用方法',},
        {prop: 'userName', label: '操作人'},
        {prop: 'operateDate', label: '操作日期'},
        {prop: 'ip', label: 'IP地址'},
    ]

}

export default globalConst;
