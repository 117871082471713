export const state = {
  tabPosition:'',
  isEdit:localStorage.getItem('isEdit'),
  dataList:[],
  sendDialogFormVisible:false,
  sendForm:{
      publishEnv:1,
      versionNum:'',
      versionDate:'',
      versionCode:'',
      remark:''
  },
  historyTable:[],
  pageConfig:{
    current:1,
    size:20,
    total:1
  },
  isFineshed:false,
  versionData:JSON.parse(localStorage.getItem('setVersionData'))?JSON.parse(localStorage.getItem('setVersionData')):{},
  versionFineshed:false,
  groupTagForm:{
    name: '',
    switch: false,
    replaceNum: 1,
    visible: 1
  },
  isShowWaring:false,
  isLogin:localStorage.getItem('isLogin') || 0,
  passwordTimeOut:localStorage.getItem('passwordTimeOut') || 0,
  dialogClose:localStorage.getItem('dialogClose') || 1,
  userData: JSON.parse(localStorage.getItem('userData'))||{
      loginName: '',
      userItCode: '',
      userPhone: '',
      userEmail: '',
      userName: '',
      roleName: '',
  },
  trailList:{
    list:[],
    count:5
  },
  value:''

}