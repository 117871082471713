import Vue from 'vue'
import request from './axios'
// 表单录入
// 左侧列表
export function getSectionList(params) {
    return request({
        url: '/api/sectionStructure/getSectionListByParentCode',
        method: 'GET',
        params,
    })
}
//左侧树形列表
export function getSectionTree(params) {
    return request({
        url: '/api/sectionStructure/getSectionTree',
        method: 'GET',
        params,
    })
}
//受试者列表
export function getTesteeList(params) {
    return request({
        url: '/api/sectionStructure/selectPatientList',
        method: 'GET',
        params,
    })
}
//访视列表
export function getVisitList(params) {
    return request({
        url: '/api/sectionStructure/getCaseListCopy',
        method: 'GET',
        params,
    })
}
//表单列表
export function getFormList(params) {
    return request({
        url: '/api/sectionStructure/selectPatientStructure',
        method: 'GET',
        params,
    })
}
// 右侧结构
export function getTreeNodeData(data) {
    return request({
        url: '/api/ddValue/getTreeNodeDataMultiple',
        method: 'POST',
        data,
    })
}
// 保存
export function addFormEntry(data) {
    return request({
        url: '/api/ddValue/saveBeans',
        method: 'POST',
        data,
    })
}
// 重复表单新增数据结构
export function getStructureDdList(data) {
    return request({
        url: '/api/ddValue/getStructureDdList',
        method: 'POST',
        data,
    })
}
// 单表单组
export function getStructureDdListGroup(data) {
    return request({
        url: '/api/ddValue/getStructureDdListGroup',
        method: 'POST',
        data,
    })
}
export function addFormEntryGroup(data) {
    return request({
        url: '/api/ddValue/saveBeanGroups',
        method: 'POST',
        data,
    })
}
export function submit(data) {
    return request({
        url: '/api/ddValue/submit',
        method: 'POST',
        data,
    })
}


// 删除表单
export function deleteFormEntry(exampleId) {
    return request({
        url: '/api/ddValue/deleteBeanByExampleId',
        method: 'POST',
        data: {
            exampleId
        },
    })
}

// 标记
export function markSection(data) {
    return request({
        url: '/api/ddValue/markSection',
        method: 'POST',
        data,
    })
}

// SDV
export function buttonSDV(data) {
    return request({
        url: '/api/ddValue/buttonSDV',
        method: 'POST',
        data,
    })
}

// 质疑列表
export function getAllQuestionList(params) {
    return request({
        url: '/api/tDataQuestion/getAllQuestionList',
        method: 'GET',
        params,
    })
}

// 质疑消息列表
export function addQuestion(data) {
    return request({
        url: '/api/tDataQuestion/addQuestion',
        method: 'POST',
        data,
    })
}

// 新增质疑
export function addQuestionList(data) {
    return request({
        url: '/api/tDataQuestion/addQuestionList',
        method: 'POST',
        data,
    })
}

// 关闭质疑
export function stopQuestionList(data) {
    return request({
        url: '/api/tDataQuestion/stopQuestionList',
        method: 'POST',
        data
    })
}
// 添加和删除备注信息
export function editRemark(data) {
    return request({
        url: '/api/ddValue/editRemark',
        method: 'POST',
        data
    })
}

// 痕迹记录
export function getTrailList(params) {
    return request({
        url: '/api/ddValue/getTrailList',
        method: 'GET',
        params,
    })
}