import Vue from 'vue'
import Vuex from 'vuex'
import {state} from './state/state.js'
import {mutations} from './mutation/mutations.js'
import {getters} from './getter/getters.js'
import {actions} from './action/action.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    state,
    mutations,
    getters,
    actions
})

export default store